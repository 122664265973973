
import * as __star__ from '@liferay/js-api/data-set';

const {

__esModule
} = __star__;

export {
__esModule
};

